import { lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const IndexPage = lazy(() => import('../pages/app'));
export const Page404 = lazy(() => import('../pages/page-not-found'));
export const PrivacyPolicyPage = lazy(() => import('../pages/privacy-policy'));
export const TimelinePage = lazy(() => import('../pages/timeline'));
export const DisclaimerPage = lazy(() => import('../pages/disclaimer'));
export const LegalNoticePage = lazy(() => import('../pages/legalNotice'));
export const TermsAndConditionsPage = lazy(() => import('../pages/terms-and-conditions'));
export const WorldContactPage = lazy(() => import('../pages/world-contact'));
export const ProductsPage = lazy(() => import('../pages/products'));
export const ProductsDetailsPage = lazy(() => import('../pages/productsDetails'));
export const RepairPage = lazy(() => import('../pages/repairs'));
export const Checkout = lazy(() => import('../pages/checkout'));
export const RedirectPage = lazy(() => import('../pages/redirect'));
export const Checktest = lazy(() => import('../pages/checktest'));
export const RedirectTestPage = lazy(() => import('../pages/redirecttest'));


const ConfigRoute = () => {
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.hash.toLowerCase();
    if (path === '#contact' || path === '#Contact') {
      navigate('/');
    } 
  }, [navigate]);  

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/hardware-repair" element={<TimelinePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      <Route path="/world-contact" element={<WorldContactPage />} />
      <Route path="/disclaimer" element={<DisclaimerPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/products-details/:productId" element={<ProductsDetailsPage />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/checktest" element={<Checktest />} />
      <Route path="/repairs-rates" element={<RepairPage />} />
      <Route path="/legal-notice" element={<LegalNoticePage />} />
      <Route path="/redirect" element={<RedirectPage/>} />
      <Route path="/redirecttest" element={<RedirectTestPage/>} />

      <Route path="/fr-fr" element={<IndexPage />} />
      <Route path="/fr-fr/" element={<IndexPage />} />
      <Route path="/es-es" element={<IndexPage />} />
      <Route path="/es-es/" element={<IndexPage />} />
      <Route path="/it-it" element={<IndexPage />} />
      <Route path="/it-it/" element={<IndexPage />} />
      <Route path="/en-en" element={<IndexPage />} />
      <Route path="/en-en/" element={<IndexPage />} />
      <Route path="/ca-ca" element={<IndexPage />} />
      <Route path="/ca-ca/" element={<IndexPage />} />
      <Route path="/world-contact-fr" element={<Navigate to="/fr-fr" replace />} />
      <Route path="/world-contact-fr/" element={<Navigate to="/fr-fr" replace />} />
      <Route path="*" element={<Navigate to="/404" replace />} />

    </Routes>
  );
};

export default ConfigRoute;
